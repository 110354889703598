<script setup lang="ts">
import type { CmsElementText } from "@shopware-pwa/composables-next";
import { useCmsElementConfig, useUrlResolver } from "#imports";
import { computed, getCurrentInstance, h } from "vue";
import { decodeHTML } from "entities";
import type { CSSProperties } from "vue";
import { getOptionsFromNode } from "@shopware-pwa/cms-base/helpers/html-to-vue/getOptionsFromNode";
import type { NodeObject } from "@shopware-pwa/cms-base/helpers/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@shopware-pwa/cms-base/helpers/html-to-vue/renderToHtml";
import { useDisplay } from 'vuetify'

const props = defineProps<{
  content: CmsElementText;
}>();
const context = getCurrentInstance();
const { getConfigValue } = useCmsElementConfig(props.content);

const mappedContent = computed<string>(() => {
  return props.content?.data?.content || getConfigValue("content");
});

const style = computed<CSSProperties>(() => ({
  alignItems: getConfigValue("verticalAlign") || 'flex-start',
}));

const hasVerticalAlignment = computed(() => !!style.value.alignItems);

const { xs, sm, md, lgAndUp } = useDisplay()

const collapsePhone = computed<boolean>(() => {
  return getConfigValue('collapsePhone');
})

const collapseTablet = computed<boolean>(() => {
  return getConfigValue('collapseTablet');
})

const collapseDesktop = computed<boolean>(() => {
  return getConfigValue('collapseDesktop');
})

const isTextCollapsable = computed(() => {
  if (xs.value && collapsePhone.value) return true
  if ((sm.value || md.value) && collapseTablet.value) return true
  if (lgAndUp.value && collapseDesktop.value) return true

  return false;
})


const CmsTextRender = () => {
  const { resolveUrl } = useUrlResolver();

  const config = {
    textTransformer: (text: string) => decodeHTML(text),
    extraComponentsMap: {
      link: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            !node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
            "a",
            {
              class:
                "underline text-base font-normal gray-900 hover:text-secondary-900",
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [...children],
          );
        },
      },
      button: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          let _class = "";
          if (node?.class) {
            const btnClass =
              "v-btn v-btn--flat v-theme--light  v-btn--density-default v-btn--rounded v-btn--variant-flat font-weight-bold";

            _class = node?.class
              .replace("btn-secondary", `${btnClass} bg-secondary`)
              .replace("btn-primary", `${btnClass} bg-primary`)
              .replace("btn-sm", `${btnClass} v-btn--size-small`);

            if (node?.class.includes("btn-sm")) {
              _class = `${_class} v-btn--size-small`
            } else {
              _class = `${_class} v-btn--size-large`
            }
          }

          return createElement(
            "a",
            {
              class: _class,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [
              createElement('span', { class: 'v-btn__overlay' }),
              createElement('span', { class: 'v-btn__underlay' }),
              createElement('span', { class: 'v-btn__content', attrs: {'data-no-activator': ''} }, ...children),
            ],
          );
        },
      },
      font: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "font";
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
          let newStyle = null;
          const styleColor = node?.attrs?.color;
          if (styleColor) {
            const currentStyle = node.attrs?.style ?? "";
            newStyle = `color:${styleColor};${currentStyle}`;
            delete node.attrs?.color;
          }

          return createElement(
            "span",
            {
              style: newStyle,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [...children],
          );
        },
      },
      img: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "img";
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
            "img",
            getOptionsFromNode(node, resolveUrl)?.attrs,
          );
        },
      },
      tableElement: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "div" && node.attrs?.class?.match(/\bsw-text-editor-table__col-selector\b/);
        },
        renderer(node: any, children: any, createElement: any) {
          return null;
        },
      }
    },
  };

  const rawHtml =
    mappedContent.value?.length > 0
      ? mappedContent.value
      : "<div class='cms-element-text missing-content-element'></div>";

  return renderHtml(rawHtml, config, h, context, resolveUrl);
};
</script>
<template>
  <div
    :class="{ 'd-flex': hasVerticalAlignment, 'flex-row': hasVerticalAlignment, 'h-100':  hasVerticalAlignment}"
    :style="style"
  >
    <div class="w-100">
      <v-expansion-panels :flat="true" v-if="isTextCollapsable">
        <v-expansion-panel bg-color="rgba(0,0,0,0)" >
          <v-expansion-panel-title>Disclaimer Text anzeigen</v-expansion-panel-title>
          <v-expansion-panel-text>
            <CmsTextRender />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <CmsTextRender v-else />
    </div>

  </div>
</template>
<style scoped>
/** Global CSS styles for text elements */
:deep(ul), :deep(dl), :deep(ol) {
  list-style-type: disc;
  padding-left: 40px;
  margin-top: 0;
  margin-bottom: 1rem;
}

:deep(ol) {
  list-style-type: decimal;
}

.v-expansion-panel {
  color: rgb(var(--v-theme-gray400));
}

.v-expansion-panel-title {
  padding-left: 0;
  padding-right: 0;
  color: rgb(var(--v-theme-gray600));
  font-size: 1rem;
  font-weight: 600;
}

:deep(.v-expansion-panel-text__wrapper)  {
  padding-left: 0;
  padding-right: 0;
}

:deep(table) {
  border: 1px solid rgb(var(--v-theme-gray100));
}

:deep(table td) {
  padding: 6px;
}

:deep(table thead) {
  background-color: rgb(var(--v-theme-gray100));
}

</style>
